@import "../bootstrap/buttons";

.btn {
	@extend .text-uppercase;
	border-radius: 999px;
}

.btn-default {
	@extend .btn-primary;
}

.btn-white {
	@extend .bg-white;
	@extend .text-secondary;
}

.btn-warning {
	@extend .text-secondary;
}

.btn-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	&:before {
		content: '';
		width: 1em;
		height: 1em;
		margin-right: .5rem;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		display: inline-block;
		vertical-align: middle;
	}
}
.btn-icon-right {
	&:before {
		content: unset;
	}
	&:after {
		content: '';
		width: 1em;
		height: 1em;
		margin-left: .5rem;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		display: inline-block;
	}
}
.btn-icon-clock {
	&:before, &:after {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z' fill='%23253753'/%3E%3C/svg%3E");
	}
}
.btn-icon-star {
	&:before, &:after {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3E%3C!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z' fill='%23fff'/%3E%3C/svg%3E");
	}
}
$fa-check-svg: 'data:image/svg+xml,%3Csvg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="svg-inline--fa fa-check fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"%3E%3Cpath fill="white" d="M173.898 439.404l-166.4-166.4c-12.497-12.497-12.497-32.759 0-45.256l45.255-45.255c12.497-12.497 32.759-12.497 45.256 0L192 312.69 413.898 90.804c12.497-12.497 32.759-12.497 45.256 0l45.255 45.255c12.497 12.497 12.497 32.759 0 45.256l-304 304c-12.497 12.497-32.759 12.497-45.256 0z"%3E%3C/path%3E%3C/svg%3E';
.btn-icon-check {
	&:before, &:after {
		background-image: url($fa-check-svg);
	}
}
.btn-icon-pdf {
	&:before, &:after {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z' fill='white'/%3E%3C/svg%3E");
	}
}
.btn-icon-login {
	&:before, &:after {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M217.9 105.9L340.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L217.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1L32 320c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM352 416l64 0c17.7 0 32-14.3 32-32l0-256c0-17.7-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32l64 0c53 0 96 43 96 96l0 256c0 53-43 96-96 96l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32z' fill='white'/%3E%3C/svg%3E");
	}
}
.btn-icon-down {
	&:before, &:after {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3C!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z' fill='white'/%3E%3C/svg%3E");
	}
}

.header-details ,
.card-cta , {
	.toggle-watchlist-item {
		
		.active-text {
			display: none !important;
		}  
		
		.icon {
			display: none !important;
		
			&.active {
				display: inline-block !important;
			}
		}
		
		&.toggle-watchlist-item-on {
			@extend .btn-icon-check;
			&.btn {
				background-color: $secondary;
				border-color: $secondary;
			}
			.inactive-text {
				display: none !important;
			}  
			.active-text {
				display: inline !important;
			}     	  
		}
	  
	}
	.detail-pills {
		.toggle-watchlist-item {
		
			@extend .btn-icon;
			@extend .btn-icon-star;
		
		}
	}
}