.hero-text {
	position: absolute;
	display: block;
	bottom: 0px;
	left: 0px;
	right: 0px;
	color: #FFF;
	padding: 30px;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.5);
	margin: 0;
}

.hero-title {
	display: block;
	padding: 30px;
	text-align: center;
	color: #FFF;
	background-color: $primary;
	font-size: 2rem;
	@include media-breakpoint-up(md) {
		font-size: 3rem;
	}	
}

.section-hero {
	position: relative;
	.carousel, .carousel-inner, .carousel-item {
		height: 100%;
	}
}

#hero-carousel {
	height: 260px;
	@include media-breakpoint-up(sm) {
		height: 380px;
	}
	@include media-breakpoint-up(md) {
		height: 540px;
	}
	div ,
	.hero-image {
		width: 100%;
		height: 100%;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
}

/*
.hero-img {
	height: 260px;
	@include media-breakpoint-up(sm) {
		height: 380px;
	}
	@include media-breakpoint-up(md) {
		height: 540px;
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
}
*/

.hero-body {
	position: relative;
	@extend .p-4;
	@extend .text-white;
	@extend .flex-column;
	@extend .align-items-center;
	@extend .justify-content-center;
	.body-bg {
		background-color: $primary;
		opacity: .75;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
	>*:not(.body-bg) {
		@extend .position-relative;
		z-index: 10;
	}
	@include media-breakpoint-up(md) {
		position: absolute;
		top: 0;
		right: auto;
		bottom: 0;
		left: 0;
		width: 360px;
	}
	img {
		@extend .mb-4;
	}
	h1 ,
	h2 ,
	h3 ,
	h4 ,
	h5 ,
	h5 ,
	p ,
	ul ,
	ol {
		@extend .text-center;
		@extend .text-white;
	}
	p {
		font-size: 1.25rem;
		@extend .mb-2;

		@include media-breakpoint-up(md) {
			font-size: 1.375rem;
		}
		@include media-breakpoint-up(lg) {
			font-size: 1.5rem;
		}
	}
}

.hero-form {
	background-color: $secondary;
	@include media-breakpoint-up(md) {
		background-color: transparent;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.container {
		@extend .h-100;
		@extend .d-flex;
		@extend .align-items-center;
		@extend .justify-content-md-end;
		@include media-breakpoint-down(lg) {
			padding: 0;
		}
	}

	form {
		@extend .d-flex;
		@extend .flex-column;
		@extend .bg-secondary;
		@extend .p-4;
		@extend .rounded;
		gap: .5rem;
		width: 100%;
		@include media-breakpoint-up(md) {
			width: 300px;
		}
		button {
			@extend .btn;
			&:not([type="submit"]) {
				@extend .btn-white;
				@extend .text-left;
				@extend .text-capitalize;
				span {
					@extend .font-weight-normal
				}
			}
			&[type="submit"] {
				@extend .btn-info;
			}
		}
		.more {
			@extend .text-white;
			@extend .d-flex;
			@extend .align-items-center;
			@extend .mt-2;
			gap: .5rem;
		}

		.form-group {
			@extend .mb-0;
			gap: .5rem;
			input {
				@extend .col;
			}
		}
	}
}
