@import "../bootstrap/forms";
@import "../bootstrap/input-group";

.form-check {
	.form-check-input {
		margin-top: .125em;
		width: 20px;
		height: 20px;
	}
	.form-check-label {
		@extend .ml-1;
	}
}

label {
    margin: 0;
    font-size: .75rem;
    line-height: 1rem;
    padding: 0;
}

.form-control {
	appearance: none;
	border-radius: 20px;
	@extend .px-3;
	@extend .py-2;
	font-size: 1.125rem;
	@extend .font-weight-bold;
	&::placeholder {
		@extend .text-secondary;
	}
}

.form-inline {
	.form-control {
		margin-right: 1rem;
	}
}

.select2-container {
    width: 100% !important;
}

.select2-container--default .select2-selection--multiple {
	appearance: none;
	border-radius: 20px;
	@extend .px-1;
	padding: .25rem .25rem .5rem .25rem;
	font-size: 1.125rem;
	@extend .font-weight-bold;
	&::placeholder {
		@extend .text-secondary;
	}
	.select2-selection__choice {
		border-radius: 15px;
		.select2-selection__choice__remove {
			border-radius: 0;
			margin-right: 2px;
			padding: 3px 4px;
		}
	}
}

.hero-form ,
.sidebar-filter {
	label {
		display: block;
		color: $white;
		margin: 0 0 0 14px;
		padding: 0;
	}
	.form-control {
		border: none;
	}
}

select.form-control {
	cursor: pointer;
}

select[multiple] {
	-ms-overflow-style: none;
	scrollbar-width: none;
	::-webkit-scrollbar {
		display: none;
	}
}
