@import "../bootstrap/card";

.card {
	border-bottom-width: 3px;
}

.card-img {
	aspect-ratio: 3 / 2;
	img {
		width: 100%;
		height: 100%;
		object-position: center;
		object-fit: cover;
	}
}
.card-title-wrap {
	 @extend .d-flex;
	 @extend .flex-wrap;
	 @extend .justify-content-between;
 }
.card-title {
	@extend .d-flex;
	@extend .flex-column;
	p {
		@extend .mb-0;
		line-height: 1.25;
	}
	h2,h3 {
		@extend .h5;
	}
}

.product-card {
	@extend .position-relative;
	@extend .mb-5;
	.card-img-wrap {
		@extend .position-relative;
		
		.card-badge {
			@extend .position-absolute;
			bottom: 0.625rem;
			right: 0.625rem;
			width: 40px;
			height: 40px;
		}
		
	}
	.card-reg, .card-lot {
		@extend .position-absolute;
		@extend .rounded-sm;
		@extend .font-weight-bold;
		@extend .text-center;
		width: 100px;
	}
	.card-reg {
		top: 0.625rem;
		right: 0.625rem;
		bottom: auto;
		left: auto;
		@extend .bg-warning;
		color: black;
	}
	.card-lot {
		top: auto;
		right: auto;
		bottom: 0.625rem;
		left: 0.625rem;
		@extend .bg-white;
		@extend .text-primary;
		@extend .border;
		@extend .border-primary;
		color: black;
	}
	.card-title-wrap {
		@extend .align-items-center;
	}
	.card-title {
		@extend .pr-3;
		p {
			@extend .text-primary;
		}
		h2 {
			@extend .mb-0;
		}
	}
	.card-time {
		width: 100%;
		@extend .d-flex;
		@extend .align-items-center;
		@extend .mb-0;
		gap: .25rem;
	}
	.card-details {
		padding: 0 $card-spacer-x;
		.details-list {
			&:not(:last-of-type) {
				@extend .border-bottom;
				@extend .border-info;
				@extend .pb-3;
				@extend .mb-3;
			}
		}
	}
	.card-foot {
		position: absolute;
		left: -1px;
		top: calc(100% - 1rem);
		right: -1px;
		bottom: auto;
		z-index: 30;
		background: white;
		border: 1px solid $card-border-color;
		border-top: none;
		border-bottom-right-radius: $card-border-radius;
		border-bottom-left-radius: $card-border-radius;
	}
	.card-cta {
		@extend .d-flex;
		gap: 1rem;
		top:auto;
		right:0;
		bottom: 0;
		left:0;
		transform: translateY(50%);
		padding: 0 $card-spacer-x;
		a, button {
			@extend .btn;
			@extend .btn-info;
			@extend .flex-fill;
			font-size: .875rem!important;
			&.cta-track {
				@extend .btn-primary;
				@extend .btn-icon;
				@extend .btn-icon-right;
				@extend .btn-icon-star;
				&.toggle-watchlist-item-on {
					@extend .btn-icon-check;
					background-color: $secondary;
					border-color: $secondary;
				}
			}
			&.cta-details {
				@extend .btn-icon;
				@extend .btn-icon-right;
				@extend .btn-icon-down;
			}
		}
	}
}


.card-portal .card-link {

	@extend .d-block;
	@extend .mt-4;
	@extend .text-uppercase;
	@extend .font-weight-bold;
	@extend .text-info;
	text-decoration: underline;
	&:hover {
		text-decoration: none;
	}
}



.events-card {
	@extend .d-flex;
	@extend .flex-column;
	@extend .align-items-center;
	@extend .bg-white;
	@extend .border;
	border-color: #DDDDDD;
	@extend .rounded;
	@extend .p-4;
	@extend .mb-4;
	
	&:hover {
		text-decoration: none;
	}

	a.event-item {
		text-decoration: none;
		text-align: center;
		display: block;
		margin: 0;
		padding: 0;
		border: 0;
	}

	img {
		@extend .mb-2;
	}
	h3 {
		@extend .text-info;
		@extend .font-weight-bold;
		font-size: 1rem;
	}
	.event-item {
		@extend .bg-light;
		@extend .d-flex;
		@extend .mt-3;
		@extend .text-decoration-none;
		color: $primary;
		letter-spacing: -.01em;
		line-height: 1;
		.event-date {
			@extend .text-white;
			@extend .d-flex;
			@extend .flex-column;
			@extend .align-items-center;
			@extend .justify-content-center;
			@extend .text-center;
			@extend .p-2;
			background-color: $primary;
			width: 55px;

			.event-weekday, .event-month {
				@extend .font-weight-bold;
				@extend .text-uppercase;
				font-size: 0.875rem;
			}
			.event-day {
				font-size: 2rem;
			}
		}
		.event-info {
			@extend .p-2;
			@extend .d-flex;
			@extend .flex-column;
			@extend .justify-content-center;

			.event-time {
				color: $calendar-headings;
				font-size: 2rem;
				@extend .mb-1;
			}
			.event-location, .event-directions {
				color: $calendar-headings;
				font-size: 0.875rem;
			}
			.event-location {
				font-weight: 600;
			}
			.event-directions {
				@extend .mt-1;
				@extend .text-primary;
				@extend .d-flex;
				@extend .align-items-center;
				&:after {
					content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3C!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath fill='%234268B0' d='M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z'/%3E%3C/svg%3E");
					width: 7px;
					height: 14px;
					display: inline-block;
					margin-left: .125rem;
				}
			}
		}
		
			
		&:hover {
			.event-date {
				background-color: darken($primary, 10%)
			}
		}
	
		&.event-item-1 {
			.event-date {
				background-color: $fleet-calendar;
			}
			&:hover {
				.event-date {
					background-color: darken($fleet-calendar, 10%)
				}
			}
		}
		
		&.event-item-2 {
			.event-date {
				background-color: $protruck-calendar;
			}
			&:hover {
				.event-date {
					background-color: darken($protruck-calendar, 10%)
				}
			}
		}
	}
	
}