@import "bootstrap/scss/breadcrumb";

.breadcrumbs {
	@extend .bg-primary;
}

.breadcrumb {
	@extend .container;
}

.breadcrumb-item {
	line-height: 1;
	>a {
		@extend .text-white;
	}
}