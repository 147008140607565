@import "../bootstrap/nav";

.nav-tabs {
	@extend .px-lg-5;
	@extend .flex-column;
	@extend .flex-lg-row;
	@include media-breakpoint-down(md) {
		gap: 1.5rem;
		background-color: white;
		padding: 1.5rem;
		border: 1px solid #ddd;
		border-bottom-width: 3px !important;
		border-radius: $border-radius;
		margin-bottom: 1.5rem;
	}
	.nav-link {
		@extend .px-4;
		@extend .py-2;
		@extend .font-weight-bold;
		font-size: 1.125rem;
		width: 100%;

		@include media-breakpoint-down(md) {
			border-radius: $border-radius;
			&.active {
				background-color: #ddd;
			}
		}
		@include media-breakpoint-up(lg) {
			height: 100%;
			&.active {
				height: calc(100% + 1px);
			}
		}
		&:not(.active) {
			@extend .bg-primary;
			@extend .text-white;
		}
	}
	.nav-item {
		@include media-breakpoint-up(lg) {
			max-width: 50%;
		}
		@extend .col;
		&-mobile {
			@extend .d-lg-none;
		}
	}
}

.tab-content {
	.tab-pane {
		@extend .bg-white;
		@extend .p-4;
		@extend .p-xl-5;
		@extend .border;
		border-top-width: 0 !important;
		border-bottom-width: 3px !important;
		border-color: #ddd;

		@include media-breakpoint-down(md) {
			border-radius: $border-radius;
		}
		@include media-breakpoint-up(lg) {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			border-bottom-right-radius: $border-radius-lg;
			border-bottom-left-radius: $border-radius-lg;
		}
		&-mobile {
			@extend .d-lg-none;
		}
	}
}