.container-content {
	max-width: 768px;
}

.container-content img {
	max-width: 100%;
}

b, strong {
    font-weight: bold;
}