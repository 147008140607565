@import "../bootstrap/navbar";

.navbar {
  @extend .align-items-center;
  .navbar-collapse {
    @extend .justify-content-lg-end;
    @extend .mt-4;
    @extend .mt-xl-5;
  }

  .navbar-nav {
    @include media-breakpoint-down(lg) {
      border-bottom: 1px solid white;
    }
    @extend .mb-4;
    @extend .mb-xl-0;
  }

  .nav-item {
    @extend .ml-xl-2;
    @include media-breakpoint-down(lg) {
      border-top: 1px solid white;
    }
  }

  .nav-link {
    font-size: 1.25rem;
    @extend .text-white;
    @extend .d-block;
    &:hover {
      color: $info !important;
    }
    @include media-breakpoint-up(lg) {
      font-weight: bold;
    }
    @include media-breakpoint-down(lg) {
      padding: .5rem 0;
    }
  }
  
  .navbar-toggler {
    @extend .border-0;
    .fl-navbar-toggler-icon {
      width: 44px;
      height: 44px;
      border: #FFFFFF 2px solid;
      border-radius: 4px;
    }
  }

  .navbar-cta {
    @extend .ml-xl-4;
    @extend .d-flex;
    gap: 1.5rem;
    .btn {
      flex: 1 1 0%;
    }
  }

  .navbar-logos {
    @extend .text-uppercase;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    @extend .justify-content-xl-end;
    @extend .mt-4;
    @extend .mt-xl-0;
    gap: 2rem;

    @include media-breakpoint-up(xl) {
      position: absolute;
      top: 1.25rem;
      right: 0;
    }
  }

  .navbar-logo {
    @extend .text-white;
    display: flex;
    @extend .align-items-center;
    gap: .25rem;
    img {
      height: 2rem;
      width: auto;
    }
  }

  .navbar-social {
    @extend .mt-4;
    @extend .mt-xl-0;
    @extend .d-flex;
    @extend .d-xl-none;
    @extend .align-items-center;
    @extend .justify-content-center;
  }

  .navbar-copy {
    @extend .mt-4;
    @extend .mb-0;
    @extend .mt-xl-0;
    @extend .d-xl-none;
    @extend .text-center;
    @extend .text-white;
    font-size: 0.875rem;
  }
}