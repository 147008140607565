@import "../bootstrap/pagination";

.pagination {
	@extend .justify-content-center;
	gap: 1rem;

	.page-item {

		.page-link {
			width: 3rem;
			height: 3rem;
			@extend .rounded-circle;
			@extend .d-flex;
			@extend .justify-content-center;
			@extend .align-items-center;
			font-size: 1.25rem;
			font-weight: 600;
			@include media-breakpoint-up(lg) {
				font-size: 1.375rem;
			}
		}

		&-arrow {
			.page-link:after {
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				width: 1.5rem;
				height: 1.5rem;
			}
			&-prev .page-link:after {
				content: '';
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3C!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z' fill='%234268B0'/%3E%3C/svg%3E");
			}
			&-next .page-link:after {
				content: '';
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3C!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z' fill='%234268B0'/%3E%3C/svg%3E");
			}
		}


	}
}